<template>
  <div class="main">
    <div style="position: fixed;width: 100%;height: 100%;display: flex;justify-content: center;align-items: flex-end;z-index: 1;">
      <div style="color: #C7C7C7;font-size: 15px;margin-bottom: 20px;">©2024 RCchat All Rights Reserved</div>
    </div>
    <div class="masking">
      <div class="login_boder"  v-loading="loading" element-loading-text="正在登录" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
        <div style="font-size: 20px;color: #F7F7F7;display: flex;align-items: center;width: 100%;justify-content: center;margin-top: 40px;margin-bottom: 40px;">欢迎使用RChat</div>
        <div style="display: flex;justify-content: center;align-items: center;width: 100%;flex-direction: column;">
          <div style="width: 80%;height: 35px;background-color: rgb(72,68,84,0.28);border-radius: 5px;display: flex;justify-content: center;align-items: center;">
            <input class="input" type="text" placeholder="请输入账号" v-model="username" v-on:input="input_username">
          </div>
          <div v-if="changelogin.login_way==0" style="width: 80%;height: 35px;background-color: rgb(72,68,84,0.28);border-radius: 5px;display: flex;justify-content: center;align-items: center;margin-top: 20px">
            <input @keyup.enter="login" class="input" type="password" placeholder="请输入密码" v-model="password" v-on:input="input_password">
          </div>
          <div v-if="changelogin.login_way==1" style="width: 80%;height: 35px;background-color: rgb(72,68,84,0.28);border-radius: 5px;display: flex;justify-content: center;align-items: center;margin-top: 20px;">
                <input @keyup.enter="login" class="input" style="width: 60%;margin-left: 10px;" type="text" placeholder="验证码" v-model="signcode.code">
                <div @click="get_signcode" style="font-size: 12px;margin-left: 10px;color: #C1C1C1;width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-right: 10px;cursor: pointer;">{{ signcode.textContent }}</div>
          </div>
          <div  @click="login" style="width: 80%;height: 35px;background-color: #4A86DB;border-radius: 5px;display: flex;justify-content: center;align-items: center;margin-top: 30px;cursor: pointer">
            <div style="font-size: 14px;color: #E1E1E1">登录</div>
          </div>
          <div style="display: flex;align-items: center;">
            <div style="margin-top: 20px;font-size: 13px;cursor: pointer;color: #A2A2A2;margin-bottom: 40px;" @click="forget_password">没有账号？注册账号</div>
          </div>
          <div style="display: flex;align-items: center;justify-content: center;margin-bottom: 20px;">
            <div style="width: 100px;height: 0.3px;background-color: rgba(40,43,54,0.8);margin-right: 20px;"></div>
            <el-tooltip class="item" effect="dark" :content="changelogin.tip" placement="top-start">
              <i @click="change_loginway" style="color: #A2A2A2;font-size: 14px;cursor: pointer;" :class="changelogin.icon"></i>
            </el-tooltip>
            <div style="width: 100px;height: 0.3px;background-color: rgba(40,43,54,0.8);margin-left: 20px;"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      username: '',
      signcode:{
            issend:false,
            code:'',
            countdown:60,
            textContent:"获取验证码"
      },
      countdownTimer:'',
      password:'',
      loading:false,
      changelogin:{
        login_way:0,
        tip:"短信验证码登录",
        icon:'el-icon-mobile-phone'
      }
    }
  },
  methods:{
    //倒计时模块
    updateCountdown() {
            if (this.signcode.countdown === 0) {
                this.signcode.textContent = '获取验证码';
                this.signcode.issend = false;
                clearInterval(this.countdownTimer)
                this.signcode.countdown = 60
            } else {
                this.signcode.countdown--;
                this.signcode.textContent = this.signcode.countdown + ' 秒后重发';
                this.signcode.issend = true;
            }
        },
        get_signcode(){
            if(!this.isValidPhoneNumber(this.username)){
                this.$message.error('手机号格式不正确！')
            }else if(!this.signcode.issend){
                this.$api.signcode({
                  user_name:this.username,
                  sign_type:'0'
                }).then(res=>{
                  if(res.data.code=='200'){
                    this.$message.success(res.data.msg)
                    this.countdownTimer = setInterval(this.updateCountdown,1000);
                  }else{
                    this.$message.error(res.data.msg)
                  }
                })
            }
        },
        isValidPhoneNumber(phoneNumber) {
            const regex = /^(13[0-9]|14[5|7|9]|15[0-3|5-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[8|9])\d{8}$/;
            return regex.test(phoneNumber);
        },
    change_loginway(){
      if(this.changelogin.login_way==0){
        this.changelogin.login_way = 1
        this.changelogin.tip = '账号密码登录'
        this.changelogin.icon = 'el-icon-user'
      }else{
        this.changelogin.login_way = 0
        this.changelogin.tip = '验证码登录'
        this.changelogin.icon = 'el-icon-mobile-phone'
      }
    },
    forget_password(){
      this.$router.push({name: 'user_register',}, () => {})
    },
    login(){
      var that = this
        if(this.changelogin.login_way==0){
          if(this.username==''||this.password==''){
            this.$message.info('请输入完整！')
          }else{
            this.fz_login({
              user_name:that.username,
              pass_word:that.password,
              login_type:'0'
            })
          }
        }else{
          if(this.username==''||this.signcode.code==''){
            this.$message.info('请输入完整！')
          }else{
            this.fz_login({
              user_name:that.username,
              pass_word:that.password,
              code:that.signcode.code,
              login_type:'1'
            })
          }
        }
    },
    input_username(e){
      this.username = e.target.value
    },
    input_password(e){
      this.password = e.target.value
    },
    //登录函数封装
    fz_login(data){
          var that = this
          that.loading = true
          that.$api.Login(data).then(response => {
            // 处理成功的响应
            if(response.data.code=='200'){
              that.loading = false
              this.$message({
              message: '登录成功',
              type: 'success'
            })
            //存储信息本地
            localStorage.setItem('username',that.username)
            localStorage.setItem('password',response.data.data.password)
            localStorage.setItem('token',response.data.data.token)
            localStorage.setItem('user_img',response.data.data.user_img)
            localStorage.setItem('nickname',response.data.data.nickname)
            localStorage.setItem('authentication',response.data.data.authentication)
            localStorage.setItem('user_id',response.data.data.user_id)
            //跳转到后台主页面
            that.$router.push({name: 'index_chat',}, () => {})
            }else{
              that.loading = false
              this.$message.error(response.data.msg)
            }
          })
          .catch(error => {
            // 处理错误的响应
            console.log(error)
            that.loading = false
            this.$message.error('登录失败，请检查您网络是否连接')
          })
    }
  }
}
</script>


<style scoped>
  .main{
    width: 100%;
    height: 100vh;
    background: #272A37;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .masking{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    flex-direction: column;
    z-index:2;
    position: fixed
  }
  .login_boder{
    width: 300px;
    background-color: #323644;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 100px;
  }
  .input{
    background: none;
    width: 90%;
    height: 100%;
    border-radius: 20px;
    border-style:none;
    outline-color: invert;   
    outline-style: none;    
    font-size: 13px; 
    color: aliceblue;
  }
  ::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;height: 0;
}
body{
  margin: 0
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
