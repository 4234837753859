<template>
    <div class="main">
      <div style="position: fixed;width: 100%;height: 100%;display: flex;justify-content: center;align-items: flex-end;z-index: 1;">
        <div style="color: #C7C7C7;font-size: 15px;margin-bottom: 20px;">©2024 RCchat All Rights Reserved</div>
      </div>
      <div class="masking">
        <div class="login_boder"  v-loading="loading" element-loading-text="正在注册" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <div style="font-size: 20px;color: #F7F7F7;display: flex;align-items: center;width: 100%;justify-content: center;margin-top: 40px;margin-bottom: 40px;">欢迎使用RChat</div>
          <div style="display: flex;justify-content: center;align-items: center;width: 100%;flex-direction: column;">
            <div style="width: 80%;height: 35px;background-color: rgb(72,68,84,0.28);border-radius: 5px;display: flex;justify-content: center;align-items: center;">
              <input class="input" type="text" placeholder="请输入账号" v-model="username">
            </div>
            <div style="width: 80%;height: 35px;background-color: rgb(72,68,84,0.28);border-radius: 5px;display: flex;justify-content: center;align-items: center;margin-top: 20px">
              <input @keyup.enter="login" class="input" type="password" placeholder="请输入密码" v-model="password" >
            </div>
            <div style="width: 80%;height: 35px;background-color: rgb(72,68,84,0.28);border-radius: 5px;display: flex;justify-content: center;align-items: center;margin-top: 20px;">
                <input class="input" style="width: 60%;margin-left: 10px;" type="text" placeholder="验证码" v-model="signcode.code">
                <div @click="get_signcode" style="font-size: 12px;margin-left: 10px;color: #C1C1C1;width: 100%;display: flex;align-items: center;justify-content: flex-end;margin-right: 10px;cursor: pointer;">{{ signcode.textContent }}</div>
            </div>
            <div @click="register" style="width: 80%;height: 35px;background-color: #4A86DB;border-radius: 5px;display: flex;justify-content: center;align-items: center;margin-top: 30px;cursor: pointer">
              <div style="font-size: 14px;color: #E1E1E1">注册</div>
            </div>
            <div style="margin-top: 20px;font-size: 13px;cursor: pointer;margin-bottom: 40px;color: #A2A2A2;" @click="tologin">已有账号？前往登录</div>
            <!-- <div style="width: 100%;display: flex;justify-content: center;">
              <div style="width: 80%;"><el-divider><div style="color: #BABABA;font-size: 11px">OR</div></el-divider></div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    export default {
    data() {
      return {
        username: '',
        password:'',
        loading:false,
        signcode:{
            issend:false,
            code:'',
            countdown:60,
            textContent:"获取验证码"
        },
        countdownTimer:''
      }
    },
    methods:{
        //倒计时模块
        updateCountdown() {
            if (this.signcode.countdown === 0) {
                this.signcode.textContent = '获取验证码';
                this.signcode.issend = false;
                clearInterval(this.countdownTimer)
                this.signcode.countdown = 60
            } else {
                this.signcode.countdown--;
                this.signcode.textContent = this.signcode.countdown + ' 秒后重发';
                this.signcode.issend = true;
            }
        },
        get_signcode(){
            if(!this.isValidPhoneNumber(this.username)){
                this.$message.error('手机号格式不正确！')
            }else if(!this.signcode.issend){
                this.$api.signcode({
                  user_name:this.username,
                  sign_type:'1'
                }).then(res=>{
                  if(res.data.code=='200'){
                    this.$message.success(res.data.msg)
                    this.countdownTimer = setInterval(this.updateCountdown,1000);
                  }else{
                    this.$message.error(res.data.msg)
                  }
                })
            }
        },
        isValidPhoneNumber(phoneNumber) {
            const regex = /^(13[0-9]|14[5|7|9]|15[0-3|5-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[8|9])\d{8}$/;
            return regex.test(phoneNumber);
        },
        tologin(){
         this.$router.push({name: 'user_login',}, () => {})
        },
        register(){
            var that = this
            if(that.username==''||that.password==''||that.signcode.code==''){
                that.$message.info("请输入完整！")
            }else{
                that.loading = true
                that.$api.register({
                'user_name':that.username,
                'password':that.password,
                'code':that.signcode.code
                }).then(response => {
                    that.loading = false
                if(response.data.code=='200'){
                    this.$message.success(response.data.msg)
                    this.$router.push({name: 'user_login',}, () => {})
                }else{
                    this.$message.error(response.data.msg)
                }
                })
                .catch(error => {
                // 处理错误的响应
                console.log(error)
                that.loading = false
                this.$message.error('登录失败，请检查您网络是否连接')
                })
            }
        }
    },
  }
  </script>
  
  
  <style scoped>
    .main{
      width: 100%;
      height: 100vh;
      background: #272A37;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .masking{
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      flex-direction: column;
      z-index:2;
      position: fixed
    }
    .login_boder{
      width: 300px;
      background-color: #323644;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 100px;
    }
    .input{
      background: none;
      width: 90%;
      height: 100%;
      border-radius: 20px;
      border-style:none;
      outline-color: invert;   
      outline-style: none;    
      font-size: 13px; 
      color: aliceblue;
    }
    ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-webkit-scrollbar {
    width: 0 !important;height: 0;
  }
  body{
    margin: 0
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  